@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poiret+One&display=swap");
body {
    height: 100svh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "lato";
    background-color: rgba(255, 255, 255, 0.1);
}

#root {
    height: 100svh;
    display: flex;
    align-items: center;
    background-image: url(../src/img/index.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.7);
}

label.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.7);
}
